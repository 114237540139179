import {
  createRouter,
  createWebHistory
} from 'vue-router'
import layoutTemplate from "@/templates/layoutTemplate"

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/404',
    name: "404",
    component: () => import('@/views/404.vue'),
    meta: {
      title: "404",
      requireAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: "Giftcard8-Best Login",
      requireAuth: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/home.vue'),
    meta: {
      title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/rate',
    name: 'Rate',
    component: () => import('@/views/rate/rate.vue'),
    meta: {
      title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/setmap',
    name: 'setmap',
    component: () => import('@/views/setmap/setmap.vue'),
    meta: {
      title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/accountCenter',
    name: 'accountCenter',
    component: layoutTemplate,
    redirect: '/account',
    meta: {
      title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: true
    },
    children: [
      {
        path: "/account",
        name: "Account",
        component: () => import("../views/accountCenter/account/account.vue"),
        meta: {
          title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
          requireAuth: true
        }
      },
      {
        path: '/sell',
        name: 'Sell',
        component: () => import("../views/accountCenter/sell/sell.vue"),
        meta: {
          title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
          requireAuth: false,
          keepAlive: false
        }
      },
      
      {
        path: '/sellHistory',
        name: 'SellHistory',
        component: () => import("../views/accountCenter/sellHistory/sellHistory.vue"),
        meta: {
          title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
          requireAuth: true
        }
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: () => import("../views/accountCenter/wallet/wallet.vue"),
        meta: {
          title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
          requireAuth: true,
          keepAlive: false
        }
      },
      {
        path: '/vipCenter',
        name: 'VipCenter',
        component: () => import("../views/accountCenter/vipCenter/vipCenter.vue"),
        meta: {
          title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
          requireAuth: true,
          keepAlive: false
        }
      },
      {
        path: '/set',
        name: 'AccountSet',
        component: () => import("../views/accountCenter/set/set.vue"),
        meta: {
          title: "Giftcard8-Best site to sell gift cards &Earn free rewards",
          requireAuth: true,
          keepAlive: false
        }
      },
    ]
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('@/views/faq/faq.vue'),
    meta: {
      title: "FAQ;Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about/about.vue'),
    meta: {
      title: "About us:Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/contact/contact.vue'),
    meta: {
      title: "Contact",
      requireAuth: false
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/terms/terms.vue'),
    meta: {
      title: "Terms Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/aml',
    name: 'Aml',
    component: () => import('@/views/aml/aml.vue'),
    meta: {
      title: "AML Terms Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/privacy/privacy.vue'),
    meta: {
      title: "Privacy Terms Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/shipping',
    name: 'Shipping',
    component: () => import('@/views/shipping/shipping.vue'),
    meta: {
      title: "Shipping Terms Giftcard8-Best site to sell gift cards &Earn free rewards",
      requireAuth: false
    }
  },
  {
    path: '/emailtmp',
    name: 'Emailtmp',
    component: () => import('@/views/email/email.vue'),
    meta: {
      title: "email",
      requireAuth: false
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 全局路由导航拦截
router.beforeEach((to) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "giftcard8";
  }
  // 是否是登录页
  if (to.path !== "/login") {
    // 不是登录页 判断是否需要登录 && sessionStorage.getItem("token")
    if (to.meta.requireAuth) { // false为不需要 true为需要
      // 再判断是否已经登录
      if (sessionStorage.getItem("token")) {
        return true;
      } else {
        router.push({ path: "/login", query : { redirect: to.fullPath } });
        return true;
      }
    } else { // 不是登录页 不需要登录
      return true;
    }
  } else {
    return true;
  }
});

export default router